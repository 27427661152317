.ant-layout-header {
  @apply p-0;
}
.ant-layout {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 0;
  background: #fff;
}

.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: transparent;
  filter: alpha(opacity=50);
}

.menu-underlined {
  padding: 0 !important;
  margin: 0 1.5rem !important;
  border: none !important;
}
.menu-underlined > a {
  border-bottom: 5px solid transparent !important;
  color: #717171 !important;
}

/* .menu-underlined.ant-menu-item-selected,
.menu-underlined.ant-menu-item-active, */
.menu-underlined--active,
.menu-underlined > a.active {
  border-bottom: 5px solid #f36b7f !important;
}

.menu-filled-sm {
  line-height: 32px;
  border: none !important;
}
.menu-filled-sm.ant-menu-item-selected,
.menu-filled-sm:hover,
.menu-filled-sm.ant-menu-item > .text-white {
  color: white !important;
  border-bottom: none !important;
}

.borderless,
.nav-menu.ant-menu-item-selected {
  border: none !important;
  border-bottom-color: transparent !important;
}
.nav-menu.ant-menu-item-selected > a {
  color: inherit;
}

.author > .ant-card-meta-detail > .ant-card-meta-title,
.author > .ant-card-meta-detail > .ant-card-meta-description {
  margin-bottom: 0px;
  font-size: 0.8rem;
}

.avatar-shadow {
  filter: drop-shadow(0px 4px 16px rgba(97, 97, 97, 0.21));
}

.showcase > .ant-card-cover {
  height: 55%;
}
.showcase > .ant-card-cover > img {
  height: 100%;
  object-fit: cover;
}

.semibold {
  font-family: 'Mont-Semibold';
}
.border-popup {
  box-shadow: none;
}
.border-popup > .ant-menu {
  border: 1px solid rgba(151, 151, 151, 0.12);
  box-shadow: none;
}
.mont-bold,
.privacy-notice b,
.privacy-notice h1,
.privacy-notice h4,
.terms-and-conditions b,
.terms-and-conditions h1,
.terms-and-conditions h4 {
  font-family: 'Mont-Bold';
}

.card-skeleton > .ant-skeleton-header {
  padding: 0;
}
.card-skeleton > .ant-skeleton-header > .ant-skeleton-avatar {
  height: 9.6rem;
  width: 100%;
}

.card-body-fill > .ant-card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.settings-avatar-skeleton > .ant-skeleton-header > .ant-skeleton-avatar {
  height: 8rem;
  width: 8rem;
}

.header-avatar .ant-skeleton-header {
  display: flex;
}

.header-avatar > .ant-skeleton-avatar {
  margin: auto;
}
.header-avatar-container > .ant-menu-submenu-title {
  height: 100% !important;
}
.header-avatar-container > .ant-menu-submenu-title > i:before,
.header-avatar-container > .ant-menu-submenu-title > i:after {
  display: none;
}
.header-avatar-container > .ant-menu-submenu-title > i {
  content: url('../img/icons/submenu.svg');
}
.ant-menu-submenu-active.header-avatar-container > .ant-menu-submenu-title > i {
  content: url('../img/icons/submenu-active.svg');
}

.ant-menu-submenu > .ant-menu,
.ant-menu-submenu-popup {
  border-radius: 6px;
}
.ant-menu-submenu .nav-menu, .ant-menu-submenu .text-primary {
  background-color: transparent !important;
  color: #000000A6 !important;
}

.signup-select > .ant-select-selector {
  height: 3.5rem !important;
  padding: 0.75rem !important;
}

.list-decimal-container > ol > li ol > li {
  display: flex;
}
