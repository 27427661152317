@import '~antd/dist/antd.less';

@font-face {
  font-family: 'Mont-Regular';
  font-weight: normal;
  src: local('Mont-Regular'),
    url('./assets/fonts/Mont-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Mont-SemiBold';
  font-weight: bold;
  src: local('Mont-SemiBold'),
    url('./assets/fonts/Mont-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Mont-Bold';
  font-weight: bold;
  src: local('Mont-Bold'),
    url('./assets/fonts/Mont-Bold.ttf') format('truetype');
}

@primary-color: #1890ff;

body {
  margin: 0;
  font-family: 'Mont-Regular', 'Mont-SemiBold', 'Mont-Bold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@primary-color: #F36B7F;@link-color: #F36B7F;@layout-body-background: #fff;@layout-header-background: #fff;@layout-sider-background-light: #f46c80;@outline-width: 5px;