@font-face {
  font-family: "geomanist-bold";
  src: url('./fonts/Geomanist-bold.woff'); 
}
@font-face {
  font-family: "geomanist-medium";
  src: url('./fonts/Geomanist-Medium.woff'); 
}
@font-face {
  font-family: "geomanist-regular";
  src: url('./fonts/Geomanist-Regular.woff'); 
}

.landing * {
  font-family: "Open Sans", Arial, sans-serif;
  color:#00000099;
  font-size:18px;
}

.landing__menu {
  height:76px;
}

.landing__menu * {
  border:none !important;
  color:#00000099 !important;    
  font-size: 14px;
}

.landing h1,.landing h2 ,.landing h3,.landing h4 {
  font-family: 'geomanist-bold',Georgia,"Times New Roman",serif;
  color:#333;
  padding-bottom: 10px;    
  line-height: 1em;
}

.landing h4 {
  font-family: 'geomanist-medium',Georgia,"Times New Roman",serif;
  font-size:30px;
  color: #f44a4a;
  letter-spacing: 4px;
  line-height:1em;
}
.landing__features_card_title {
  color: #333 !important;
  font-size:26px !important;
  letter-spacing: 1px !important;
}

.landing h1 {
  font-size: 79px;
  color: #31404f;
}

.landing h2 {
  font-size: 48px;
}
.landing__content * {
  font-family: 'geomanist-regular',Georgia,"Times New Roman",serif;
  color: #708491;
}

.landing__row_content {
  width: 89%;
  max-width: 89%;
  display:flex;
  flex-flow: row wrap;
  margin:auto;
}

.landing__welcome_image {
  @media (max-width: 980px){
      margin-bottom: 30px;
  }
}

.landing__row_content_text {
  text-align:right;
  @media (max-width: 980px) {
      text-align:center;
  }
}
.landing__menu_fixed {
  position: 'fixed'; 
  z-index: 1;
  width: '100%';
  border-bottom:'none';
}

.landing__text1 {
  font-size:27px;
  line-height: 24.3px;
  max-width: 68%;
  margin-bottom: 5.82%;
}

.landing__text1_right {
  margin-left: auto!important;
  margin-right: 0px!important;
  @media (max-width: 980px){
    margin-right: auto!important;
  }
}

.landing__text3 {
  font-size:20px;
  max-width: 62%;
  font-size: 20px;
  line-height: 28px;
  margin-top: 20px!important;
  margin-bottom: 63px!important;
}

.landing__color_red {
  color: #f44a4a;
}

.landing__row_about {
  background-color: #f2f6f9;
}
.landing__row,.landing__row_about {
  background-attachment:fixed;
  background-position: center;
  background-size: cover;
  padding-top:4rem;
}
.landing__button,.landing__button_primary,.landing__button_primary:hover {
  border-color: rgba(0,0,0,0);
  letter-spacing: 3px;
  text-transform: uppercase;
  background-color: #f44a4a;
  padding: 10px 25px !important;
  border-width: 0px;
  height:fit-content;
  padding: 0.3em 1em;
  font-weight: 500;
  line-height: 2.1rem !important;
  margin-bottom: 10px!important;
}
.landing__button,.landing__button:hover {
  padding: 10px 23px !important;
  background-color: #708491;
  margin-bottom: 10px!important;
}
.landing__button_text{
  color: #f2f6f9 !important;
  font-size: 20px;
}

.landing__features {
  padding-top: 111px;
  text-align:center;
}

.landing__features_header  {
  padding: 27px 0;
  margin: auto;
}

.landing__features_collection {
  flex-wrap: wrap;
  display: flex;
  width: 80%;
  max-width: 1080px;
  margin: auto;
}

.landing__features_card {
  background-color: #f2f6f9;
  text-align:center;
  padding-top: 29px!important;
  padding-bottom: 55px!important;
  margin-right: 11%;    
  padding: 0 24px;
  @media (max-width: 980px) {
    width: 100%;
    margin-bottom: 30px;
  }
}

.landing__features_card_image {
  margin: auto;
  margin-bottom: 30px;
}
.landing__features_collection_row {
  @media only screen and (min-width: 1350px){
    padding: 27px 0;
  }
  @media (min-width: 981px){
    padding: 2% 0;
  }
}

.landing__row_discover {
  padding-top: 149px;
  padding-right: 0px;
  padding-bottom: 159px;
  padding-left: 0px;
}
.landing__row_discover_container {
  background-size: contain;
  background-position: center right;
  background-repeat:no-repeat;
  @media (max-width: 980px) {
    background-image: none!important;
  }
}

.landing__discover_divider {
  margin-bottom:2.75%;
}
.landing__anchor * {
  position: static !important;
}

.landing__anchor,.landing__anchor * {
  padding-left:0px
}

.landing__anchor_text{
  padding-bottom:25px;
}