.studentList__studentTransfer_size {
  font-size: 32px;
  margin-right: 20px;
}

.studentList__list_margin {
  margin-top: 20px;
}

.transfer__button_marginTop {
  margin-top: 20px;
}
