.collapse__extra_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.assignment__margin {
  margin-top: 50px;
}

.student__reviewAssignment_margin {
  margin-top: 20px;
}

.student__joinLesson_colorPadding {
  display: block;

  width: 100%;
  padding: 5px;
  text-align: center;
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  cursor: pointer;
}

.assignment__listAction_flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .account__logo_content {
    width: 241px;
    height: 100px;
    position: relative;
  }
}

@media only screen and (min-width: 768px) {
  .account__logo_content {
    width: 272px;
    height: 100px;
    position: relative;
  }
}

.account__avatar_content {
  width: 104px;
  height: 104px;
  position: relative;
}

.account__avatar_radius {
  width: 100%;
  height: 100%;

  background: #ffffff;
  box-shadow: 0px 4px 11px rgba(215, 217, 223, 0.57);

  background-image: url("../../assets/lesson_ongoing/avatar.png");
}

.account__avatar_user {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 20px;
  left: 25px;
  background-repeat: no-repeat;
  background-image: url("../../assets/lesson_ongoing/student (9) 1.png");
}

.account__logo {
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-image: url("../../assets/lesson_ongoing/LOGO.png");
}

@media only screen and (max-width: 767px) {
  .lessonList__ul_width {
    width: 241px;
    margin: 0;
    padding: 0;
  }
}

@media only screen and (min-width: 768px) {
  .lessonList__ul_width {
    width: 272px;
    margin: 0;
    padding: 0;
  }
}

.lessonList__li_flexMargin {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 30px;
  margin-top: 10px;
}

.lessonList__li_linkColor {
  font-size: 14px;
  color: #4f537b;
}

.lessonList_li_linkActive {
  background: #fbfcfe;
}

.classCode_displayInline {
  display: inline;
}

.assignment__addMoreButton {
  box-sizing: border-box;
  font-size: 30px;
  line-height: 0;
}

.assignment__uploadButton_margin {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.student__sider_padding {
  padding: 20px 0 0 30px;
}

.sider__logout_normal {
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: red;
}

.assignment__pictureLIst {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  clear: both;
}

.assignment__pictureList_container {
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}

.assigment__pictureList_item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-size: 14px;
}

.assigment__pictureList_listInfo {
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  transition: background-color 0.3s;
}

.headers_mg {
  margin: 20px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #4f537b;
}

.studentContent {
  padding: 0 20px;
}
@media only screen and (max-width: 767px) {
  .studentContent {
    padding: 0 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .studentContent {
    padding: 0 20px;
  }
}
// @media only screen and (min-width: 1024px) and (max-width: 1439px) {
//   .studentContent {
//     padding: 0 40px;
//   }
// }
// @media only screen and (min-width: 1440px) {
//   .studentContent {
//     padding: 0 40px;
//   }
// }

.modalFilesName {
  // font-family: Mont-Regular;
  font-size: 16px;
  line-height: 19px;
  color: #4f537b;
  margin-bottom: 20px;
}
.maskStyle {
  background-color: transparent;
}
