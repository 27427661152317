.download__card {
  width: 140px;
  height: 200px;
  margin-bottom: 10px;
  border-radius: 8.72659px;
  border: 0.872659px solid #dfe0e0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.download__name {
  margin: 20px 0;
  width: 100%;
  padding: 0 10px;
  // font-family: "Mont-Regular";
  font-size: 14.6298px;
  line-height: 17px;
  text-align: center;
  color: #4f537b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.downloads__button_color {
  text-align: center;
  color: #5b9dff;
  border-color: #5b9dff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
