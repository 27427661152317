.App {
  width: 100vw;
  height: 100vh;
  /* background: #f0f2f5; */
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .App {
    margin: 0;
    padding: 0;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app__header_padding {
  padding: 0 150px;
}

.layout__content_positionMargin {
  margin: 0;
  padding: 0 150px;
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .app__header_padding {
    padding: 0 50px;
  }

  .layout__content_positionMargin {
    margin: 0;
    padding: 0 50px;
    height: 100%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.layout__content_padding {
  padding: 45px 20px 0 20px;
}

.layout__header_light {
  background-color: #fff;
  color: #282c34;
  border: 1px solid #f0f2f5;
}
