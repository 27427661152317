.boxContainer {
  min-height: 376px;
  // min-width: 346px;
  // margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid #dfe0e0;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.boxTitleStyle {
  align-self: flex-start;
  margin-left: 17px;
  margin-top: 24px;
  // font-family: "Mont-SemiBold";
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #4f537b;
}
.boxPicStyle {
  width: 140px;
  height: 100px;
  margin: 60px 40px 0;
}
.boxContextStyle {
  // font-family: "Mont-Regular";
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #b0bac9;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .boxContainer {
    min-height: 376px;
    // min-width: 350px;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 0 10px;
    border: 1px solid #dfe0e0;
    box-sizing: border-box;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .boxContainer {
    min-height: 376px;
    // min-width: 350px;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 0 10px;
    border: 1px solid #dfe0e0;
    box-sizing: border-box;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin: 0 20px;
  }
}
