.boxContainer {
  min-height: 376px;
  // min-width: 346px;
  // margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid #dfe0e0;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.boxTitleStyle {
  align-self: flex-start;
  margin-left: 17px;
  margin-top: 24px;
  margin-bottom: 20px;
  // font-family: "Mont-SemiBold";
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #4f537b;
}
@media screen and (max-width: 767px) {
  .boxContainer {
    min-height: 376px;
    // min-width: 350px;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 0 10px;
    border: 1px solid #dfe0e0;
    box-sizing: border-box;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .boxContainer {
    min-height: 376px;
    // min-width: 350px;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 0 10px;
    border: 1px solid #dfe0e0;
    box-sizing: border-box;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin: 0 20px;
  }
}

.relativeBox {
  flex: 1;
  width: 100%;
  padding: 0 17px;
  position: relative;
}
.UploadBtn {
  font-size: 15px;
  line-height: 21px;
  // font-family: 'Mont-Bold';
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  background-color: #5b9dff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 25px;
  bottom: 30px;
}

.markedBox {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.markedTips {
  // font-family: Mont-Regular;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #b0bac9;
}
.UploadTestSub {
  font-size: 15px;
  line-height: 21px;
  width: 80%;
  // font-family: 'Mont-Bold';
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  background-color: #5b9dff;
  border-radius: 4px;
  margin-top: 20px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
