.scroll_text {
  animation: changebox 10s linear infinite;
  animation-play-state: running;
  animation-fill-mode: forwards;

  &:hover {
    animation-play-state: paused;
    cursor: default;
  }
  @keyframes changebox {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}
