.wrapper {
  background-color: #f46c80;
}
.IllustBox {
  width: 236px;
  height: 158px;
  margin: 30px 0;
  align-self: center;
}
@media screen and (max-width: 768px) {
  .wrapper {
    height: 395px;
    // min-width: 344px;
    border-radius: 21px;
    margin: 34.5px 0;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .IllustBox {
    width: 236px;
    height: 158px;
    margin: 30px 0;
    align-self: center;
  }
  .comingTips {
    // font-family: "Mont-Bold";
    // overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .remindTips {
    // font-family: "Mont-SemiBold";
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding-right: 35px;
    color: #ffffff;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .wrapper {
    height: 395px;
    // min-width: 344px;
    border-radius: 21px;
    margin: 34.5px 0;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    // align-items: center;
  }
  .IllustBox {
    width: 40%;
    height: auto;
    margin: 30px 0;
    align-self: center;
  }
  .comingTips {
    // font-family: "Mont-Bold";
    // overflow: hidden;
    width: 100%;
    align-self: flex-start;
    white-space: nowrap;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .remindTips {
    // font-family: "SemiBold";
    width: 100%;
    align-self: flex-start;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding-right: 35px;
    color: #ffffff;
  }
}

@media screen and (min-width: 1025px) {
  .wrapper {
    background-color: #f46c80;
    height: 244px;
    border-radius: 21px;
    margin: 60px 0;
    padding: 0 70px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
  .IllustBox {
    width: 38%;
    max-width: 430px;
    height: auto;
    // margin: 30px 0;
    position: relative;
    top: 52px;
    right: 26px;
  }
  .comingTips {
    // font-family: "Mont-Bold";
    // overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
    font-size: 40px;
    line-height: 33px;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .remindTips {
    // font-family: "Mont-SemiBold";
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
  }
}
.finishedWrapper {
  background-color: #5b9dff;
}
